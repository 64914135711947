import { useGetSponsorshipsChangesMutation } from "src/services/sponsorshipsServices";
import {
  LargeHeadingStyled,
  SectionHeaderStyled,
} from "../../../components/custom/texts/texts.styled";
import { Card, Flex, Select } from "@nubeteck/components";
import { Input, Layout, Table, Form, Button } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect } from "react";
import { IPatrociniosCambio } from "src/Interfaces/sponsorship";
import { useMediaQuery } from "src/hooks";
import { useNavigate } from "react-router-dom";
import { FormatterUtils } from "@nubeteck/utils";

const { Content } = Layout;

const SponsorshipsChanges: React.FC = () => {
  const [pageNumber, setPageNumber] = React.useState(1);
  const [rowsCount, setRowsCount] = React.useState(5);
  const [studentName, setStudentName] = React.useState("");
  const [sponsorshipName, setSponsorshipName] = React.useState("");
  const [filtroUsado, setFiltroUsado] = React.useState(null);
  const isMobile = useMediaQuery("(max-width: 1180px)");

  const [getSponsorships, { data, isLoading }] = useGetSponsorshipsChangesMutation();

  const navigate = useNavigate();
  useEffect(() => {
    getSponsorships({
      rowsCount,
      page: pageNumber,
      estudianteNombre: studentName,
      patrocinioNombre: sponsorshipName,
      usado: filtroUsado,
    });
  }, [getSponsorships, pageNumber, rowsCount, studentName, sponsorshipName, filtroUsado]);

  const columns = React.useMemo<ColumnsType<IPatrociniosCambio>>(
    () => [
      {
        title: "Usuario",
        dataIndex: "usuario",
        width: 150,
        fixed: isMobile ? undefined : "left",
      },
      {
        title: "Fecha modificación",
        dataIndex: "fechaModificacion",
        width: 120,
        fixed: isMobile ? undefined : "left",
        render: (value) => new Date(value)?.toLocaleString(),
        sorter: (a, b) =>
          new Date(a.fechaModificacion).getTime() - new Date(b.fechaModificacion).getTime(),
      },
      {
        title: "ID",
        dataIndex: "matricula",
        width: 120,
      },
      {
        title: "Estudiante",
        dataIndex: "estudianteNombre",
        width: 150,
      },
      {
        title: "Patrocinio",
        dataIndex: "patrocinioNombre",
        width: 150,
      },
      {
        title: "Tipo de patrocinio",
        dataIndex: "patrocinioTipo",
        width: 150,
      },
      {
        title: "Carrera",
        dataIndex: "carreraCodigo",
        width: 150,
      },
      {
        title: "Año",
        dataIndex: "anio",
        width: 80,
      },
      {
        title: "Periodo",
        dataIndex: "periodo",
        width: 90,
      },
      {
        title: "No. de acuerdo",
        dataIndex: "acuerdo",
        width: 120,
      },
      {
        title: "Referencia",
        dataIndex: "referencia",
        width: 150,
      },
      {
        title: "Monto límite",
        dataIndex: "monto",
        render: (value) => FormatterUtils.number(value.toFixed(2)),
        width: 120,
      },
      {
        title: "Porcentaje",
        dataIndex: "porcentaje",
        render: (value) => `${value}%`,
        width: 110,
      },
      {
        title: "Moneda",
        dataIndex: "monedaNombre",
        width: 150,
      },
      {
        title: "Fecha elegibilidad",
        dataIndex: "fechaElegibilidad",
        width: 120,
      },
      {
        title: "Estado",
        dataIndex: "estaActivo",
        render: (value) => (value ? "Activo" : "Inactivo"),
        width: 100,
      },
      {
        title: "Ha sido utilizado",
        dataIndex: "usado",
        render: (value) => (value ? "Si" : "No"),
        width: 100,
      },
      {
        title: "Monto utilizado",
        dataIndex: "montoUtilizado",
        render: (value) => (value > 0 ? FormatterUtils.number(value) : 0),
        width: 100,
      },
    ],
    [isMobile],
  );
  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <SectionHeaderStyled>
        <LargeHeadingStyled>Historial de cambios en patrocinios</LargeHeadingStyled>
        <Button title="" type="default" onClick={() => navigate("/admin/sponsorships")}>
          Volver atrás
        </Button>
      </SectionHeaderStyled>
      <Content style={{ marginTop: "16px" }}>
        <SectionHeaderStyled style={{ alignContent: "end", gap: "5px" }}>
          <Form.Item label="Búsqueda por nombre o id">
            <Input
              type="text"
              onChange={(e) => setStudentName(e.target.value)}
              placeholder="Búsqueda por nombre o id"
            />
          </Form.Item>{" "}
          <Form.Item label="Búsqueda por patrocinio">
            <Input
              type="text"
              onChange={(e) => setSponsorshipName(e.target.value)}
              placeholder="Búsqueda por patrocinio"
            />
          </Form.Item>
          <Form.Item label="Filtro por uso">
            <Select
              allowClear
              defaultValue={null}
              options={[
                {
                  label: "Mostrar todos",
                  value: null,
                },
                {
                  label: "Mostrar utilizados",
                  value: true,
                },
                {
                  label: "Mostrar no utilizados",
                  value: false,
                },
              ]}
              placeholder="Seleccione un  filtro"
              onChange={(value) => {
                setFiltroUsado(value);
              }}
            />
          </Form.Item>
        </SectionHeaderStyled>

        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          <Table<IPatrociniosCambio>
            loading={isLoading}
            columns={columns}
            dataSource={data?.data?.result}
            rowKey={(v) => `${v.estudiantePatrocinioId} ${v.patrocinioNombre}`}
            pagination={{
              onChange(page, pageSize) {
                setPageNumber(page);
                setRowsCount(pageSize);
              },
              pageSize: rowsCount,
              current: pageNumber,
              total: data?.data?.pageCount * rowsCount,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
            scroll={{ x: 300 }}
          />
        </Card>
      </Content>
    </Flex>
  );
};

export default SponsorshipsChanges;
